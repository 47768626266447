
<template>
 
    <v-card
        class="ma-4"
    >
    <v-card-text>
        <v-row align="center">
            <v-text-field 
                :value="item.table_type.description"
                readonly
                class="mx-2"
            ></v-text-field>
        </v-row>
        <v-row>
            <v-col cols="3">Farbe: {{item.meta.text}}</v-col>
            <v-col cols="3">Größe: {{item.meta.size}}</v-col>
            <v-col cols="3">min: {{item.min}} bar</v-col>
            <v-col cols="3">max: {{item.max}} bar</v-col>
            </v-row>
        <v-row height="500px" align="center">
            <v-col
            cols="6"
            >        
            <v-simple-table
                fixed-header
                height="300px"
            >
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Druck (bar)
                    </th>
                    <th class="text-left">
                        Durchfluß (l/min)
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="point in item.setpoints"
                    :key="point[0]"
                    >
                    <td>{{ point[0] }}</td>
                    <td>{{ point[1] }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table> 
          
            </v-col>
            <v-col cols="6">
                <v-card height="300px">

                    <v-chart ref="chart" :option="chartOption" />
                </v-card>
            </v-col>
        </v-row>
    </v-card-text>
    </v-card>
</template>


<script>
// import { power } from "../../../helper"
    const math = require("../../../utils/math")

    export default {

        props:{
            item: {
                required: false,
                type: Object,
                default: () => {
                    return  {
                        name: null,
                        table_type: null,
                        color: {},
                        setpoints:[],
                        titel:null    
                    }
                }
            },
            
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }            
        },
    
        data() {
            return {

                data: [
                    [96.24, 11.35],
                    
                    ],
            }
 
    
        },

        computed:{

            chartOption(){
                return {
                        dataset: [
                            {
                                source: this.data
                            },
                            {
                                // source: this.data
                                transform: {
                                type: 'ecStat:powerregression'
                                }
                            }
                        ],
                        title: {
                            text: 'Durfluss',
                            left: 'center',
                            top: 16
                        },
                        legend: {
                            orient: 'horizontal',
                            top: 'bottom',
                        },                        
                        tooltip: {
                            trigger: 'axis',
                            label:'Flow',
                            axisPointer: {
                                type: 'cross'
                            },
                            // formatter: '{a0}: ' + '{c0}'.split(',') + '<br />{a1}: {c1}'
                        },
                        xAxis: {
                            name: 'bar',
                            axisLabel: {
                                // formatter: '{value} l/min'
                            },
                            splitLine: {
                                lineStyle: {
                                    type: 'dashed'
                                },
                                
                                interval: 'auto' 
                            },
                        },
                        yAxis: {
                            
                            name: 'l/min',
                            axisLabel: {
                                // formatter: '{value} bar'
                            },
                            min: 0,
                            splitLine: {
                                lineStyle: {
                                    type: 'dashed'
                                }
                            }
                        },
                        series: [
                            {
                                name: 'Durchfl. Tabelle',
                                type: 'scatter'
                            },
                            {
                                name: 'Durchfl. berechnet',
                                type: 'line',
                                smooth: true,
                                datasetIndex: 1,
                                symbolSize: 0.1,
                                symbol: 'circle',
                                label: { show: true, fontSize: 16 },
                                labelLayout: { dx: -100 },
                                encode: { label: 2, tooltip: 1}
                            }                      
                        ]
                } 
            }    
                     
            


        },
    
        methods:{

        },
    
        watch: { 
      
            item: function(newVal) { // watch it
                console.log('Prop changed: item', newVal, this.item)
            },
            
            isVisible: function(newVal) { // watch it
                console.log('Prop changed: isVisible', newVal, this.item)
                if(newVal){
                    this.data = this.item.setpoints
                   
                    const result1 = math.power(this.data,{precision: 3});
                    console.log("result1", result1)
                }
            },             
        },
    
        mounted() {
          console.log('mounted')
          this.data = this.item.setpoints

        }
    }
</script>
    